@use '../core' as kb;

html {
  overflow-y: scroll; // force scrollbar to avoid width changes
  overscroll-behavior-y: none; // fix some weird overscroll issues, that occur in chrome devtools mobile emulation
}

html,
.kb-scrollbar {
  @supports not selector(::-webkit-scrollbar) {
    scrollbar-color: kb.$color--on-secondary-variant--low kb.$color--secondary-variant;
    scrollbar-width: thin;
  }


  &::-webkit-scrollbar-button {
    display: none
  }

  &::-webkit-scrollbar-thumb {
    background-color: kb.$color--on-secondary-variant--low;
    border-radius: 0
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: kb.$color--on-secondary-variant--high;
  }

  &::-webkit-scrollbar-track {
    background-color: kb.$color--secondary-variant
  }

  &::-webkit-scrollbar {
    width: kb.pxToRem(10);
    height: kb.pxToRem(6);
  }
}

@font-face {
  font-family: "Noto Sans Semi Condensed Fallback";
  font-style: normal;
  font-weight: 400;
  font-stretch: condensed;
  src: local('Arial Narrow'), local('Arial');
  ascent-override: 90.7938%;
  descent-override: 24.8855%;
  size-adjust: 117.7393%;
}
@font-face {
  font-family: "Noto Sans Semi Condensed Fallback";
  font-style: normal;
  font-weight: 700;
  font-stretch: condensed;
  src: local('Arial Narrow Bold'), local('Arial Bold'), local('Arial Narrow'), local('Arial');
  ascent-override: 91.0893%;
  descent-override: 24.9665%;
  size-adjust: 117.3573%;
}
@font-face {
  font-family: "Noto Sans Semi Condensed Fallback";
  font-style: italic;
  font-weight: 400;
  font-stretch: condensed;
  src: local('Arial Narrow Italic'), local('Arial Italic'), local('Arial Narrow'), local('Arial');
  ascent-override: 96.9378%;
  descent-override: 26.5695%;
  size-adjust: 110.2769%;
}
@font-face {
  font-family: "Noto Sans Semi Condensed Fallback";
  font-style: italic;
  font-weight: 700;
  font-stretch: condensed;
  src: local('Arial Narrow Bold Italic'), local('Arial Bold Italic'), local('Arial Narrow'), local('Arial');
  ascent-override: 97.007%;
  descent-override: 26.5884%;
  size-adjust: 110.1983%;
}
@use '../../core' as kb;

[kb-heading] {
  display: block;
  font-weight: normal;
  color: kb.$color--on-background--high;
  margin: 0;
  h1, h2, h3, h4 {
    font-weight: inherit;
    margin: 0;
  }

  & {
    @include kb.typography--large(); // This is used for variable-heading with type none
  }

  @at-root {
    #{selector-append(h1, &)},
    & > h1 {
      @include kb.typography--extra-large();
    }
    #{selector-append(h2, &)},
    & > h2 {
      @include kb.typography--larger();
    }
    #{selector-append(h3, &)},
    & > h3 {
      @include kb.typography--large();
    }
    #{selector-append(h4, &)},
    & > h4 {
      @include kb.typography--medium-large();
    }

    #{selector-append(h4, &)}[type="divider"],
    &[type="divider"] > h4 {
      @include kb.typography--large();
    }

    #{selector-append(h2, &)}[type="bar"],
    &[type="bar"] > h2 {
      @include kb.typography--large();
    }
    #{selector-append(h4, &)}[type="bar"],
    &[type="bar"] > h4 {
      @include kb.typography--large();
    }

    #{selector-append(h2, &)}[type="floating"],
    &[type="floating"] > h2 {
      @include kb.typography--large();
    }
    #{selector-append(h4, &)}[type="floating"],
    &[type="floating"] > h4 {
      @include kb.typography--large();
    }
  }

  &[type="divider"] {
    border-bottom: kb.pxToRem(1) solid currentColor;

    margin-bottom: kb.$gap--outer-column;
    margin-left: kb.$gap--outer-row;
    padding-right: kb.$gap--inner-row;
  }

  @at-root {
    #{selector-append(h1, &)} {
      padding: kb.$gap--inner--hybrid;
      &:not(#\9) {
        margin-bottom: 0;
      }
    }
  }

  &[type="bar"],
  &[type="floating"] {
    padding: kb.$gap--inner--hybrid;
  }

  &[type="bar"] {
    background-color: kb.$color--primary;
    color: kb.$color--on-primary;
  }

  @at-root {
    #{selector-append(h1, &)}[type="bar"], // h1[kb-heading][type="bar"]
    &[type="bar"] > h1 { // [kb-heading][type="bar"] > h1
      display: inline-block;
      justify-self: left;
    }
  }

  &:not([type="divider"]):not([type="bar"]):not([type="floating"]) {
    &:not(:last-child) {
      margin-bottom: kb.$gap--main-column;
    }
  }
}

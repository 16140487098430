@use '../../core' as kb;

[kb-link] {
  color: inherit;
  border-bottom: 1px solid currentColor;
  text-decoration: none;

  transition: color kb.$speed--slow;
  // Hover color is set individually as it differs based on surface color

  outline-width: kb.$width--outline;
  &:focus {
    outline-style: solid;
    outline-color: currentColor;
  }
}

$var--size-extra-extra-small: --kb-typography--size-extra-extra-small;
$size-extra-extra-small: var($var--size-extra-extra-small);
$var--height-extra-extra-small: --kb-typography--height-extra-extra-small;
$height-extra-extra-small: var($var--height-extra-extra-small);
@mixin extra-extra-small() {
  font-size: $size-extra-extra-small;
  line-height: $height-extra-extra-small;
}

$var--size-extra-small: --kb-typography--size-extra-small;
$size-extra-small: var($var--size-extra-small);
$var--height-extra-small: --kb-typography--height-extra-small;
$height-extra-small: var($var--height-extra-small);
@mixin extra-small() {
  font-size: $size-extra-small;
  line-height: $height-extra-small;
}

$var--size-small: --kb-typography--size-small;
$size-small: var($var--size-small);
$var--height-small: --kb-typography--height-small;
$height-small: var($var--height-small);
@mixin small() {
  font-size: $size-small;
  line-height: $height-small;
}

$var--size-normal: --kb-typography--size-normal;
$size-normal: var($var--size-normal);
$var--height-normal: --kb-typography--height-normal;
$height-normal: var($var--height-normal);
@mixin normal() {
  font-size: $size-normal;
  line-height: $height-normal;
}

$var--size-medium-large: --kb-typography--size-medium-large;
$size-medium-large: var($var--size-medium-large);
$var--height-medium-large: --kb-typography--height-medium-large;
$height-medium-large: var($var--height-medium-large);
@mixin medium-large() {
  font-size: $size-medium-large;
  line-height: $height-medium-large;
}

$var--size-large: --kb-typography--size-large;
$size-large: var($var--size-large);
$var--height-large: --kb-typography--height-large;
$height-large: var($var--height-large);
@mixin large() {
  font-size: $size-large;
  line-height: $height-large;
}

$var--size-larger: --kb-typography--size-larger;
$size-larger: var($var--size-larger);
$var--height-larger: --kb-typography--height-larger;
$height-larger: var($var--height-larger);
@mixin larger() {
  font-size: $size-larger;
  line-height: $height-larger;
}

$var--size-extra-large: --kb-typography--size-extra-large;
$size-extra-large: var($var--size-extra-large);
$var--height-extra-large: --kb-typography--height-extra-large;
$height-extra-large: var($var--height-extra-large);
@mixin extra-large() {
  font-size: $size-extra-large;
  line-height: $height-extra-large;
}

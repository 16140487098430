@use 'sass:map';
@use 'breakpoints' as *;

@mixin -write-category-breakpoint($category, $breakpointName) {
  @each $variableName, $value in map.get($category, $breakpointName) {
    #{$variableName}: #{$value};
  }
}

@mixin writeTheme($theme) {
  :root {
    @each $_, $category in $theme {
      @include -write-category-breakpoint($category, default);
    }
    @each $breakpointName, $breakpoint in $breakpoints {
      @include lower($breakpoint) {
        @each $_, $category in $theme {
          @include -write-category-breakpoint($category, 'lower-' + $breakpointName);
        }
      }
      @include greater($breakpoint) {
        @each $_, $category in $theme {
          @include -write-category-breakpoint($category, 'greater-' + $breakpointName);
        }
      }
    }
  }
}

@use '../../core';

@use '@knorr-bremse-portals/font-noto/dist/noto-sans-semi-condensed-fallback.css';

body {
  font-family: "Noto Sans Semi Condensed", "Noto Sans JP", "Noto Sans KR", "Noto Sans SC", "Noto Sans Semi Condensed Fallback", sans-serif !important;
  [lang="ja"] { // Japanes
    font-family: "Noto Sans JP", "Noto Sans Semi Condensed", "Noto Sans KR", "Noto Sans SC", "Noto Sans Semi Condensed Fallback", sans-serif !important;
  }
  [lang="ko"] { // Korean
    font-family: "Noto Sans KR", "Noto Sans Semi Condensed", "Noto Sans JP", "Noto Sans SC", "Noto Sans Semi Condensed Fallback", sans-serif !important;
  }
  [lang="zh"] { // Simplified Chinese
    font-family: "Noto Sans SC", "Noto Sans Semi Condensed", "Noto Sans JP", "Noto Sans KR", "Noto Sans Semi Condensed Fallback", sans-serif !important;
  }
}

$all: (
  default: (
    core.$typography--var--size-extra-extra-small: core.pxToRem(12),
    core.$typography--var--height-extra-extra-small: core.pxToRem(12), // This seems wrong, height = size?

    core.$typography--var--size-extra-small: core.pxToRem(14),
    core.$typography--var--height-extra-small: core.pxToRem(18),

    core.$typography--var--size-small: core.pxToRem(16),
    core.$typography--var--height-small: core.pxToRem(20),

    core.$typography--var--size-normal: core.pxToRem(16),
    core.$typography--var--height-normal: core.pxToRem(20),

    core.$typography--var--size-medium-large: core.pxToRem(17),
    core.$typography--var--height-medium-large: core.pxToRem(22),

    core.$typography--var--size-large: core.pxToRem(18),
    core.$typography--var--height-large: core.pxToRem(23),

    core.$typography--var--size-larger: core.pxToRem(19),
    core.$typography--var--height-larger: core.pxToRem(23),

    core.$typography--var--size-extra-large: core.pxToRem(20),
    core.$typography--var--height-extra-large: core.pxToRem(24),
  ),
  greater-medium-small: (
    core.$typography--var--size-extra-extra-small: core.pxToRem(14),
    core.$typography--var--height-extra-extra-small: core.pxToRem(14), // This seems wrong, height = size?

    core.$typography--var--size-extra-small: core.pxToRem(15),
    core.$typography--var--height-extra-small: core.pxToRem(19),

    core.$typography--var--size-small: core.pxToRem(16),
    core.$typography--var--height-small: core.pxToRem(20),

    core.$typography--var--size-normal: core.pxToRem(18),
    core.$typography--var--height-normal: core.pxToRem(22),

    core.$typography--var--size-medium-large: core.pxToRem(20),
    core.$typography--var--height-medium-large: core.pxToRem(24),

    core.$typography--var--size-large: core.pxToRem(22),
    core.$typography--var--height-large: core.pxToRem(26),

    core.$typography--var--size-larger: core.pxToRem(24),
    core.$typography--var--height-larger: core.pxToRem(28),

    core.$typography--var--size-extra-large: core.pxToRem(26),
    core.$typography--var--height-extra-large: core.pxToRem(30),
  ),
  greater-larger: (
    core.$typography--var--size-extra-extra-small: core.pxToRem(14),
    core.$typography--var--height-extra-extra-small: core.pxToRem(14), // This seems wrong, height = size?

    core.$typography--var--size-extra-small: core.pxToRem(16),
    core.$typography--var--height-extra-small: core.pxToRem(20),

    core.$typography--var--size-small: core.pxToRem(18),
    core.$typography--var--height-small: core.pxToRem(24),

    core.$typography--var--size-normal: core.pxToRem(20),
    core.$typography--var--height-normal: core.pxToRem(28),

    core.$typography--var--size-medium-large: core.pxToRem(22),
    core.$typography--var--height-medium-large: core.pxToRem(28),

    core.$typography--var--size-large: core.pxToRem(24),
    core.$typography--var--height-large: core.pxToRem(28),

    core.$typography--var--size-larger: core.pxToRem(28),
    core.$typography--var--height-larger: core.pxToRem(32),

    core.$typography--var--size-extra-large: core.pxToRem(32),
    core.$typography--var--height-extra-large: core.pxToRem(38),
  ),
);

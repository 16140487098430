// Page
$var--page-row: --kb-gap--page-row;
$page-row: var($var--page-row);


// Main
$var--main-column: --kb-gap--main-column;
$main-column: var($var--main-column);

$var--main-row: --kb-gap--main-row;
$main-row: var($var--main-row);

$main: $main-column $main-row;


$var--main-column--unrelated-extra: --kb-gap--main-column--unrelated-extra;
$main-column--unrelated-extra: var($var--main-column--unrelated-extra);


// Outer
$var--outer-column: --kb-gap--outer-column;
$outer-column: var($var--outer-column);

$var--outer-row: --kb-gap--outer-row;
$outer-row: var($var--outer-row);

$outer: $outer-column $outer-row;


$var--outer-column--related: --kb-gap--outer-column--related;
$outer-column--related: var($var--outer-column--related);

$var--outer-row--related: --kb-gap--outer-row--related;
$outer-row--related: var($var--outer-row--related);

$outer--related: $outer-column--related $outer-row--related;

// Inner
$var--inner-column: --kb-gap--inner-column;
$inner-column: var($var--inner-column);

$var--inner-row: --kb-gap--inner-row;
$inner-row: var($var--inner-row);

$inner: $inner-column $inner-row;


$var--inner-column--text: --kb-gap--inner-column--text;
$inner-column--text: var($var--inner-column--text);

$var--inner-row--text: --kb-gap--inner-row--text;
$inner-row--text: var($var--inner-row--text);

$var--inner-column--small-text: --kb-gap--inner-column--small-text;
$inner-column--small-text: var($var--inner-column--small-text);

$inner--text: $inner-column--text $inner-row--text;
$inner--small-text: $inner-column--small-text $inner-row--text;

$inner--hybrid: $inner-column--text $inner-row;
$inner--small-hybrid: $inner-column--small-text $inner-row;

@use '../core';

[kb-layout="grid"] {
  display: grid;
  grid-template-columns: repeat(core.$layout--grid-columns, minmax(0, 1fr));
  gap: core.$gap--outer;

  &[kb-grid-variant="main"] {
    gap: core.$gap--main;

    & > *:not([kb-next-grid-row="related"]) {
      margin-bottom: core.$gap--main-column--unrelated-extra;
    }
  }

  @mixin -grid-item-xcol($columns) {
    max-width: 100%; // without this, firefox overflows the grid
    min-width: 0;
    grid-column: span calc(min(#{core.$layout--grid-columns}, #{$columns}));
  }

  @for $i from 1 through 12 {
    [kb-grid-item-columns="#{$i}"] {
      @include -grid-item-xcol($i);
    }
  }
}

[kb-layout="flex"] {
  display: flex;
  flex-wrap: wrap;
  gap: core.$gap--outer;
  align-items: center;

  [kb-flex-item="dynamic"] {
    flex: 1; // Basically flex-grow 1
  }
  [kb-flex-item="full"] {
    flex-basis: 100%;
  }
  [kb-flex-item="half"] {
    flex-basis: 45%; // We cannot use 50% as the gap is not accounted for, use grow instead
    flex-grow: 1;
  }
}

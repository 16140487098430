@use '../../core' as kb;

[kb-section] {
  padding-left: kb.$gap--inner-row;
  padding-right: kb.$gap--inner-row;
  [kb-section] {
    padding-left: initial;
    padding-right: initial;
  }

  &:not(:last-child) {
    margin-bottom: kb.$gap--main-column;
  }
}

// Breakpoints must be in px because media queries do not work correctly with rems
// See breakpoints.ts
$-extra-small: 320px;
$-small: 480px;
$-medium-small: 768px;
$-medium: 900px;
$-medium-large: 1080px; // invented by koenigd (Full HD Mobile Portrait)
$-large: 1440px; // full-hd with 125% is 1536px wide
$-larger: 1680px;
$-extra-large: 1920px;

$breakpoints: (
  extra-small: $-extra-small,
  small: $-small,
  medium-small: $-medium-small,
  medium: $-medium,
  medium-large: $-medium-large,
  large: $-large,
  larger: $-larger,
  extra-large: $-extra-large,
);

@mixin lower($breakpoint) {
  @media screen and (max-width: #{$breakpoint}) {
    @content;
  }
}

@mixin greater($breakpoint) {
  @media screen and (min-width: #{$breakpoint + 1}) {
    @content;
  }
}

@mixin lower-extra-small {
  @include lower($-extra-small) {
    @content;
  }
}
@mixin greater-extra-small {
  @include greater($-extra-small) {
    @content;
  }
}

@mixin lower-small {
  @include lower($-small) {
    @content;
  }
}
@mixin greater-small {
  @include greater($-small) {
    @content;
  }
}

@mixin lower-medium-small {
  @include lower($-medium-small) {
    @content;
  }
}
@mixin greater-medium-small {
  @include greater($-medium-small) {
    @content;
  }
}

@mixin lower-medium {
  @include lower($-medium) {
    @content;
  }
}
@mixin greater-medium {
  @include greater($-medium) {
    @content;
  }
}

@mixin lower-medium-large {
  @include lower($-medium-large) {
    @content;
  }
}
@mixin greater-medium-large {
  @include greater($-medium-large) {
    @content;
  }
}

@mixin lower-large {
  @include lower($-large) {
    @content;
  }
}
@mixin greater-large {
  @include greater($-large) {
    @content;
  }
}

@mixin lower-larger {
  @include lower($-larger) {
    @content;
  }
}
@mixin greater-larger {
  @include greater($-larger) {
    @content;
  }
}

@mixin lower-extra-large {
  @include lower($-extra-large) {
    @content;
  }
}
@mixin greater-extra-large {
  @include greater($-extra-large) {
    @content;
  }
}

// Primary
$var--primary: --kb-color--primary;
$primary: var($var--primary);

$var--on-primary: --kb-color--on-primary;
$on-primary: var($var--on-primary);

$var--on-primary--low: --kb-color--on-primary--low;
$on-primary--low: var($var--on-primary--low);


// Secondary
$var--secondary: --kb-color--secondary;
$secondary: var($var--secondary);

$var--on-secondary: --kb-color--on-secondary;
$on-secondary: var($var--on-secondary);

$var--on-secondary--high: --kb-color--on-secondary--high;
$on-secondary--high: var($var--on-secondary--high);

$var--on-secondary--low: --kb-color--on-secondary--low;
$on-secondary--low: var($var--on-secondary--low);

$var--on-secondary--success: --kb-color--on-secondary--success;
$on-secondary--success: var($var--on-secondary--success);

$var--on-secondary--error: --kb-color--on-secondary--error;
$on-secondary--error: var($var--on-secondary--error);

$var--secondary--surface-disabled: --kb-color--secondary--surface-disabled;
$secondary--surface-disabled: var($var--secondary--surface-disabled);

$var--on-secondary--surface-disabled: --kb-color--on-secondary--surface-disabled;
$on-secondary--surface-disabled: var($var--on-secondary--surface-disabled);


// Secondary variant
$var--secondary-variant: --kb-color--secondary-variant;
$secondary-variant: var($var--secondary-variant);

$var--on-secondary-variant: --kb-color--on-secondary-variant;
$on-secondary-variant: var($var--on-secondary-variant);

$var--on-secondary-variant--high: --kb-color--on-secondary-variant--high;
$on-secondary-variant--high: var($var--on-secondary-variant--high);

$var--on-secondary-variant--low: --kb-color--on-secondary-variant--low;
$on-secondary-variant--low: var($var--on-secondary-variant--low);

$var--on-secondary-variant--low-variant: --kb-color--on-secondary-variant--low-variant;
$on-secondary-variant--low-variant: var($var--on-secondary-variant--low-variant);

$var--on-secondary-variant--success: --kb-color--on-secondary-variant--success;
$on-secondary-variant--success: var($var--on-secondary-variant--success);

$var--on-secondary-variant--error: --kb-color--on-secondary-variant--error;
$on-secondary-variant--error: var($var--on-secondary-variant--error);

$var--secondary-variant--surface-disabled: --kb-color--secondary-variant--surface-disabled;
$secondary-variant--surface-disabled: var($var--secondary-variant--surface-disabled);

$var--on-secondary-variant--surface-disabled: --kb-color--on-secondary-variant--surface-disabled;
$on-secondary-variant--surface-disabled: var($var--on-secondary-variant--surface-disabled);


// Background
$var--background: --kb-color--background;
$background: var($var--background);

$var--on-background: --kb-color--on-background;
$on-background: var($var--on-background);

$var--on-background--high: --kb-color--on-background--high;
$on-background--high: var($var--on-background--high);

$var--on-background--low: --kb-color--on-background--low;
$on-background--low: var($var--on-background--low);

$var--on-background--low-variant: --kb-color--on-background--low-variant;
$on-background--low-variant: var($var--on-background--low-variant);

$var--on-background--success: --kb-color--on-background--success;
$on-background--success: var($var--on-background--success);

$var--on-background--error: --kb-color--on-background--error;
$on-background--error: var($var--on-background--error);

$var--on-background--error-low: --kb-color--on-background--error-low;
$on-background--error-low: var($var--on-background--error-low);

@use '../../core' as kb;

[kb-paragraph] {
  @include kb.typography--normal();
  color: kb.$color--on-background;

  margin: 0;
  &:not(:last-child) {
    margin-bottom: kb.$gap--main-column;
  }

  a[kb-link]:hover {
    color: kb.$color--on-background--high;
  }
}

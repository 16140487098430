@use '../../core' as kb;

[kb-list] {
  @include kb.typography--normal();
  color: kb.$color--on-background;

  padding-left: 2ch;
  margin-left: 2px; // this fixes that the dots do not start before text in another line

  margin-top: 0;
  &:not(:last-child) {
    margin-bottom: kb.$gap--main-column;
  }

  a[kb-link]:hover {
    color: kb.$color--on-background--high;
  }
}
ul[kb-list] {
  list-style-type: square;
}
